import React from "react"
import styled from 'styled-components'

import Layout from "../components/Layout"
import EggSvg from "../images/Egg"
import SEO from "../components/SEO"

const Wrapper = styled.div`
  display: block;
  height: auto;
  margin: 0 auto;
  grid-area: 2 / -2 / -2 / 2;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Wrapper>
      <EggSvg/>
    </Wrapper>
  </Layout>
)

export default IndexPage
